import {
  ApolloClient,
  FetchResult,
  gql,
  MutationFunctionOptions,
  MutationHookOptions,
  NormalizedCacheObject,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { useCallback } from 'react';
import { GetSsoPayload } from '../__generated__/GetSsoPayload';
import { CheckSsoResponse } from '../__generated__/CheckSsoResponse';
import { CheckSsoConnected } from '../__generated__/CheckSsoConnected';
import { GetTopic, GetTopic_topicByExternalId_postStream_posts, GetTopicVariables } from '../__generated__/GetTopic';
import { UpdateQuery } from './types';

export const GET_TOPIC_QUERY = gql`
  query GetTopic($id: ID!, $userToken: String, $offset: Int) {
    topicByExternalId(id: $id, userToken: $userToken, offset: $offset) {
      id
      likeCount
      closed
      title
      postsCount
      postStream {
        posts {
          id
          yours
          topicId
          avatarTemplate
          replyToPostNumber
          createdAt
          name
          username
          postNumber
          cooked
          actionsSummary {
            actionId
            canAct
            count
            acted
          }
        }
        allPosts {
          postNumber
          username
          replyToPostNumber
        }
        streams
      }
    }
  }
`;

export const SSO_QUERY = gql`
  mutation GetSsoPayload($return_sso_url: String!, $referer: String!) {
    sso(return_sso_url: $return_sso_url, referer: $referer) {
      sig
      sso
    }
  }
`;

export const SSO_QUERY_CHECK_RESPONSE = gql`
  mutation CheckSsoResponse($sso: String!, $sig: String!) {
    ssoResponse(sso: $sso, sig: $sig) {
      isConnected
      userToken
      email
      username
      referer
    }
  }
`;

export const SSO_QUERY_CHECK_CONNECTED = gql`
  mutation CheckSsoConnected {
    ssoStatus {
      isValid
      username
      avatar
      email
    }
  }
`;

export const TOPIC_MUTATION_RESPONSE = gql`
  mutation AddCommentTopic($topicId: Int, $postId: Int, $raw: String, $itemId: String) {
    addComment(topicId: $topicId, postId: $postId, raw: $raw, itemId: $itemId) {
      topicId
    }
  }
`;

export const LIKE_COMMENT_MUTATION = gql`
  mutation LikeComment($postId: Int, $type: String) {
    likeComment(postId: $postId, type: $type) {
      topicId
    }
  }
`;

export const DELETE_COMMENT_MUTATION = gql`
  mutation deleteComment($postId: Int) {
    deleteComment(postId: $postId)
  }
`;

export const useTopicQuery = () => {
  const [queryFetchData, { loading, error, data, fetchMore, refetch }] = useLazyQuery<GetTopic, GetTopicVariables>(
    GET_TOPIC_QUERY,
  );

  const fetchData = useCallback(
    (opt) => {
      queryFetchData(opt);
    },
    [queryFetchData],
  );

  const fetchMoreComments = useCallback(
    async (variables: GetTopicVariables) => {
      const updateQuery: UpdateQuery<GetTopic> = (previousResult, { fetchMoreResult }) => {
        const topicByExternalId = {
          ...previousResult,
          topicByExternalId: {
            ...previousResult.topicByExternalId,
            postStream: {
              ...previousResult.topicByExternalId.postStream,
              posts: [
                ...previousResult.topicByExternalId.postStream.posts,
                ...fetchMoreResult.topicByExternalId.postStream.posts,
              ],
              allPosts: [...fetchMoreResult.topicByExternalId.postStream.allPosts],
            },
          },
        };

        return topicByExternalId;
      };

      await fetchMore({ updateQuery, variables });
    },
    [fetchMore],
  );

  return { refetch, fetchData, fetchMoreComments, loading, error, topic: data?.topicByExternalId };
};

export const useSsoPayload = (
  options?: MutationHookOptions<GetSsoPayload, { return_sso_url: string; referer: string }>,
): [
  (
    options?: MutationFunctionOptions<GetSsoPayload, { return_sso_url: string; referer: string }>,
  ) => Promise<FetchResult<GetSsoPayload>>,
  any | undefined,
  boolean,
] => {
  const [ssoPayload, { data, loading }] = useMutation<GetSsoPayload>(SSO_QUERY, options);

  return [ssoPayload, data?.sso, loading];
};

export const ssoPayload = (client: ApolloClient<NormalizedCacheObject>, return_sso_url: string, referer: string) => {
  return client.mutate<GetSsoPayload>({ mutation: SSO_QUERY, variables: { return_sso_url, referer } });
};

export const checkSsoResponse = (
  client: ApolloClient<NormalizedCacheObject>,
  sso: string | string[],
  sig: string | string[],
) => {
  return client.mutate<CheckSsoResponse>({ mutation: SSO_QUERY_CHECK_RESPONSE, variables: { sso, sig } });
};

export const addComment = (
  client: ApolloClient<NormalizedCacheObject>,
  topicId: number,
  postId: number | null,
  raw: string,
  itemId: string | null = null,
) => {
  return client.mutate<GetTopic_topicByExternalId_postStream_posts>({
    mutation: TOPIC_MUTATION_RESPONSE,
    variables: { topicId, postId, raw, itemId },
  });
};

export const likeComment = (client: ApolloClient<NormalizedCacheObject>, postId: number, type: string) => {
  return client.mutate<GetTopic_topicByExternalId_postStream_posts>({
    mutation: LIKE_COMMENT_MUTATION,
    variables: { postId, type },
  });
};

export const deleteComment = (client: ApolloClient<NormalizedCacheObject>, postId: number) => {
  return client.mutate<boolean>({
    mutation: DELETE_COMMENT_MUTATION,
    variables: { postId },
  });
};

export const checkIsConnected = async (client: ApolloClient<NormalizedCacheObject>) => {
  const { data } = await client.mutate<CheckSsoConnected>({
    mutation: SSO_QUERY_CHECK_CONNECTED,
  });

  return data?.ssoStatus;
};
